import type { DialogEvent, DialogState } from '~/orderingApp/types';

export const initialState: DialogState = {
  context: {},
  previousValue: 'none',
  value: 'none',
};

export const dialogReducer = (prevState: DialogState = initialState, event: DialogEvent) => {
  const nextState = { ...prevState, context: { ...prevState.context }, previousValue: prevState.value };
  switch (prevState.value) {
    case 'none':
      switch (event.type) {
        case 'ordering.alert':
          switch (event.context.issue) {
            case 'invalidOrderInfo':
              nextState.value = 'invalidOrderInfo';
              break;
            case 'invalidSubmit':
              nextState.value = 'invalidSubmit';
              nextState.context = { errorMessage: event.context.errorMessage };
              break;
            default:
              break;
          }
          break;
        case 'ordering.autoOpenDialog':
          nextState.value = 'locationSelection';
          break;
        case 'ordering.seeProfile':
          switch (event.context.isSignedIn) {
            case true:
              nextState.value = 'profile';
              break;
            case false:
              nextState.value = 'signIn';
              break;
          }
          break;
        case 'ordering.seePrivacyPolicy':
          nextState.value = 'privacyPolicy';
          break;
        case 'ordering.seeOrderInfo':
          if (event.context?.fulfillmentType) {
            nextState.context = {
              fulfillmentType: event.context.fulfillmentType,
            };
          }
          nextState.value = 'orderInfo';
          break;
        case 'ordering.seeItem':
          nextState.value = 'menuItemDetail';
          nextState.context = {
            cartItemId: event.context.cartItemId,
            menuItemId: event.context.menuItemId,
          };
          break;
        case 'ordering.seeMyCart':
          nextState.value = 'shoppingCart';
          break;
        case 'ordering.seeHours':
          nextState.value = 'menuHours';
          break;
        case 'ordering.scheduleForLater':
          nextState.value = 'cartScheduler';
          break;
        case 'ordering.seeCodeInfo':
          nextState.value = 'seeCodeInfo';
          nextState.context = { codeType: event.context.codeType, offerId: event.context.offerId };
          break;
        default:
          break;
      }
      break;
    case 'orderInfo':
      switch (event.type) {
        case 'ordering.changeLocation':
          nextState.value = 'locationSelection';
          break;
        case 'ordering.scheduleForLater':
          nextState.value = 'cartScheduler';
          break;
        case 'ordering.updateCartInfoSuccess':
          nextState.value = 'none';
          break;
        case 'ordering.dismissDialog':
          nextState.context.fulfillmentType = undefined;
          nextState.value = 'none';
          break;
        default:
          break;
      }
      break;
    case 'cartScheduler':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'orderInfo';
          break;
        case 'ordering.selectTime':
          nextState.value = 'orderInfo';
          break;
        default:
          break;
      }
      break;
    case 'menuHours':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          break;
        default:
          break;
      }
      break;
    case 'locationSelection':
      switch (event.type) {
        case 'ordering.changeLocation':
          nextState.value = 'orderInfo';
          break;
        case 'ordering.updateCartInfoSuccess':
          nextState.value = 'none';
          break;
        default:
          break;
      }
      break;
    case 'menuItemDetail':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = prevState.previousValue;
          nextState.context = initialState.context;
          break;
        case 'ordering.addCartItemSuccess':
          switch (event.context.viewportCurrentSize) {
            case 'desktop':
              nextState.value = 'shoppingCart';
              nextState.context = initialState.context;
              break;
            case 'mobile':
              nextState.value = 'none';
              nextState.context = initialState.context;
              break;
            default:
              break;
          }
          break;
        case 'ordering.updateCartItemSuccess':
          nextState.value = prevState.previousValue;
          nextState.context = initialState.context;
          break;
        case 'ordering.seeProfile':
          nextState.value = 'signIn';
          break;
        default:
          break;
      }
      break;
    case 'invalidSubmit':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          nextState.context = initialState.context;
          break;
        default:
          break;
      }
      break;
    case 'privacyPolicy':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          break;
        default:
          break;
      }
      break;
    case 'shoppingCart':
      switch (event.type) {
        case 'ordering.seeItem':
          nextState.value = 'menuItemDetail';
          nextState.context = event.context;
          break;
        case 'ordering.keepShopping':
          nextState.value = 'none';
          break;
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          break;
        case 'ordering.seeCodeInfo':
          nextState.value = 'seeCodeInfo';
          nextState.context = { codeType: event.context.codeType, offerId: event.context.offerId };
          break;
        case 'ordering.goToCheckout':
          nextState.value = 'none';
          break;
        case 'ordering.alert':
          switch (event.context.issue) {
            case 'invalidItems':
              nextState.value = 'invalidCartItems';
              nextState.context = { issue: event.context.issue };
              break;
            case 'invalidScheduledItems':
              nextState.value = 'invalidCartItems';
              nextState.context = { issue: event.context.issue };
              break;
            case 'invalidSubtotal':
              nextState.value = 'invalidSubtotal';
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    case 'invalidCartItems':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          nextState.context = initialState.context;
          break;
        default:
          break;
      }
      break;
    case 'signIn':
      switch (event.type) {
        case 'ordering.signInSuccess':
          nextState.value = 'none';
          break;
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          break;
        default:
          break;
      }
      break;
    case 'invalidOrderInfo':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          break;
        case 'ordering.scheduleForLater':
          nextState.value = 'cartScheduler';
          break;
        case 'ordering.seeOrderInfo':
          nextState.value = 'orderInfo';
          break;
        default:
          break;
      }
      break;
    case 'profile':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = 'none';
          break;
        case 'ordering.seeMyCart':
          nextState.value = 'shoppingCart';
          break;
        case 'ordering.seeItem':
          nextState.value = 'menuItemDetail';
          nextState.context = {
            cartItemId: event.context.cartItemId,
            menuItemId: event.context.menuItemId,
          };
          break;
        default:
          break;
      }
      break;
    case 'invalidSubtotal':
      switch (event.type) {
        case 'ordering.dismissDialog':
          nextState.value = prevState.previousValue;
          break;
        default:
          break;
      }
      break;
    case 'seeCodeInfo':
      switch (event.type) {
        case 'ordering.dismissDialog':
          if (prevState.previousValue === 'seeCodeInfo') {
            nextState.value = 'none';
          } else {
            nextState.value = prevState.previousValue;
          }
          break;
        default:
          break;
      }
      break;
  }
  return nextState;
};

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from '@popmenu/common-ui';
import { nl2br } from '../../utils/utils';

import CustomLink from '../shared/CustomLink';
import CustomImg from '../../shared/CustomImg';
import Grid from '../../shared/Grid';
import PhotoGallery from '../photos/PhotoGallery';
import { AH } from '../shared/AccessibleHeading';
import { setOrderingOfferCode } from '../../shared/ConsumerActions';

const fontAlignment = {
  afa_center: 'center',
  afa_left: 'left',
  afa_right: 'right',
};

const AnnouncementContent = ({ announcement, announcementFontAlignment, onLinkClick, titleId }) => {
  const hasContent = !!(announcement.heading || announcement.content);
  const hasPhoto = !!announcement.photoUrl;
  const isModalStacked = announcement.displayType !== 'display_banner' && announcement.modalDisplayType === 'mdt_stacked';
  const dispatch = useDispatch();

  const offerImageUrl = announcement.marketingOffer?.orderingOffer?.imageUrl;
  const { width: imgWidth, height: imgHeight } = offerImageUrl ?
    { height: announcement.marketingOffer.orderingOffer.height, width: announcement.marketingOffer.orderingOffer.width } :
    (announcement.photoUploadedPhoto || {});

  return (
    <Grid container alignItems="center">
      {hasPhoto && (
        <Grid item sm={(isModalStacked || !hasContent) ? 12 : 4} xs={12}>
          <div className="pm-announcement-photo">
            <CustomImg
              alt={announcement.marketingOffer?.orderingOffer?.name || announcement.photoAlt || announcement.heading}
              size="sm"
              priority="primary"
              src={offerImageUrl || announcement.photoUrl}
              waitForScrolled={false}
              width={imgWidth}
              height={imgHeight}
            />
            <PhotoGallery
              alt={announcement.heading}
              aria-label="Open expanded image in gallery"
              className="pm-announcement-photo-button"
              photos={[{
                alt: announcement.photoAlt,
                eventableId: announcement.id,
                eventableType: 'Announcement',
                photoUrl: announcement.photoUrl,
                thumbnailUrl: announcement.thumbnailUrl,
              }]}
            />
          </div>
        </Grid>
      )}
      {hasContent && (
        <Grid item sm={(isModalStacked || !hasPhoto) ? 12 : 8} xs={12}>
          {offerImageUrl && (
            <Box
              display="flex"
              justifyContent="center"
              marginBottom={3}
            >
              <CustomImg
                className="pm-announcement-offer-image"
                alt={announcement.marketingOffer.orderingOffer.name}
                size="sm"
                priority="primary"
                src={offerImageUrl}
                waitForScrolled={false}
                width={announcement.marketingOffer.orderingOffer.imageWidth}
                height={announcement.marketingOffer.orderingOffer.imageHeight}
              />
            </Box>
          )}
          {announcement.heading && (
            <AH id={titleId} variant="h2" style={{ color: 'inherit', textAlign: fontAlignment[announcementFontAlignment] }}>
              {announcement.heading}
            </AH>
          )}
          {announcement.content && (
            <p style={{ textAlign: fontAlignment[announcementFontAlignment] }}>
              {nl2br(announcement.content)}
            </p>
          )}
          {(announcement.linkUrl && announcement.linkName) && (
            <div style={{ textAlign: fontAlignment[announcementFontAlignment] }}>
              <CustomLink
                className="pm-announcement-button"
                onClick={() => {
                  if (announcement.marketingOffer?.orderingOffer?.code) {
                    dispatch(setOrderingOfferCode(announcement.marketingOffer.orderingOffer.code));
                  }

                  // Don't call function for inline annoucements
                  if (typeof onLinkClick === 'function') {
                    onLinkClick();
                  }
                }}
                type="button"
                url={announcement.linkUrl}
              >
                {announcement.linkName}
              </CustomLink>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};

AnnouncementContent.defaultProps = {
  announcementFontAlignment: 'afa_center',
  onLinkClick: null,
  titleId: null,
};

AnnouncementContent.propTypes = {
  announcement: PropTypes.shape({
    content: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  announcementFontAlignment: PropTypes.oneOf(['afa_center', 'afa_left', 'afa_right']),
  onLinkClick: PropTypes.func,
  titleId: PropTypes.string,
};

export default AnnouncementContent;

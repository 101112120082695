import { useState, useCallback, useEffect } from 'react';
import type React from 'react';
import { visualRegressionsMode } from './visualRegressionsMode';
import { useScrollEventListener } from './useScrollEventListener';
import type { VisibilityOptions } from './isVisible';
import { isVisible } from './isVisible';
import { useScreenReaderMode } from './screenReaderMode/useScreenReaderMode';

type Props = { reset? : boolean } & VisibilityOptions;

export type ScrollObserverRef<TElement extends Element = Element> = React.Ref<TElement> & { current?: TElement };

export const useScrollObserver = <TElement extends Element = Element>({ reset, ...visibiltyOptions }: Props = {}) => {
  const [scrolledTo, setScrolledTo] = useState<boolean>(false);
  const { screenReaderMode } = useScreenReaderMode();
  const [observedNode, setObservedNode] = useState<TElement>();

  const checkVisibility = () => {
    if (!reset && isVisible(observedNode, visibiltyOptions)) {
      setScrolledTo(true);
    }
  };

  useEffect(() => {
    if (reset) {
      setScrolledTo(false);
    }
  }, [reset]);

  useScrollEventListener((scrolledTo || reset) ? undefined : checkVisibility);

  const scrollObserverRef: ScrollObserverRef<TElement> = useCallback((node: TElement | null) => {
    setObservedNode(node ?? undefined);
  }, []);
  scrollObserverRef.current = observedNode;

  return { scrolledTo: scrolledTo || screenReaderMode || !!visualRegressionsMode, scrollObserverRef };
};
